// ------------------------------------------------------------------------------
// Import Packages
// ------------------------------------------------------------------------------
import React from "react";
import ReactDom from "react-dom/client";

// ------------------------------------------------------------------------------
// Import Bootstrap-CSS
// ------------------------------------------------------------------------------
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// ------------------------------------------------------------------------------
// Import Custom-CSS
// ------------------------------------------------------------------------------
import './app.css';

// ------------------------------------------------------------------------------
// Import Root
// ------------------------------------------------------------------------------
import App from "./App";

const root = ReactDom.createRoot(document.getElementById('root'));

root.render(<App/>);

