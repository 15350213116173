import React from 'react';
import HeroImage from '../assets/img/hero.png';
import LogoImage from '../assets/img/logo.png';

const Hero = () => {
  return (
    <div className="hero-image-container">
      <img src={HeroImage} alt="Hero" className="hero-image" />
      <img src={LogoImage} alt="Logo" className="hero-image-head fade-in-delayed" />
    </div>
  );
}

export default Hero;
