import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import Image01 from '../assets/screenshots/shot_01.png';
import Image02 from '../assets/screenshots/shot_02.png';
import Image03 from '../assets/screenshots/shot_03.png';
import Image04 from '../assets/screenshots/shot_04.png';
import Image05 from '../assets/screenshots/shot_05.png';
import Image06 from '../assets/screenshots/shot_06.png';
import Image07 from '../assets/screenshots/shot_07.png';
import Image08 from '../assets/screenshots/shot_08.png';
import Image09 from '../assets/screenshots/shot_09.png';
import Image10 from '../assets/screenshots/shot_10.png';

import Image11 from '../assets/screenshots/shot_11.png';
import Image12 from '../assets/screenshots/shot_12.png';
import Image13 from '../assets/screenshots/shot_13.png';
import Image14 from '../assets/screenshots/shot_14.png';
import Image15 from '../assets/screenshots/shot_15.png';
import Image16 from '../assets/screenshots/shot_16.png';
import Image17 from '../assets/screenshots/shot_17.png';
import Image18 from '../assets/screenshots/shot_18.png';
import Image19 from '../assets/screenshots/shot_19.png';
import Image20 from '../assets/screenshots/shot_20.png';

import Image21 from '../assets/screenshots/shot_21.png';
import Image22 from '../assets/screenshots/shot_22.png';
import Image23 from '../assets/screenshots/shot_23.png';
import Image24 from '../assets/screenshots/shot_24.png';
import Image25 from '../assets/screenshots/shot_25.png';
import Image26 from '../assets/screenshots/shot_26.png';
import Image27 from '../assets/screenshots/shot_27.png';
import Image28 from '../assets/screenshots/shot_28.png';
import Image29 from '../assets/screenshots/shot_29.png';
import Image30 from '../assets/screenshots/shot_30.png';

const Show = () => {
  return (
    <div className="slideshow-container">
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image01}
            alt="Bild 1"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image02}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image03}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image04}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image05}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image06}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image07}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image08}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image09}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image10}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image11}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image12}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image13}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image14}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image15}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image16}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image17}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image18}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image19}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image20}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image21}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image22}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image23}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image24}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image25}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image26}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image27}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image28}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image29}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image30}
            alt="Bild 2"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Show;
